import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";


const TerminalAppAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem, modalSize, reload})=>{
    const [initialValues, setInitialValues] = useState({});
    const defaultValues = {application_type: 1};
    const [hiddenConfigFileName, setHiddenConfigFilename] = useState(true);
    const [hiddenConfig, setHiddenConfig] = useState(true);
    const [application_type, setApplicationType] = useState(defaultValues.application_type);
    const config = {
        addUrl: '/api/v1/terminal_apps/add/',
        editUrl: edit ? `/api/v1/terminal_apps/${editItem?.id}/edit/` : '',
        dataUrl: edit? `/api/v1/terminal_apps/${editItem?.id}/` : '',
        pageTitle: !edit ? 'Terminal App Add Page' : `Edit ${editItem?.product_name || ''}`,
        colNo: 2,
        cancelButtonText: 'Cancel',
        saveButtonText: !edit ? 'Save' : 'Edit',
        addSuccessMessage: !edit ? 'Terminal App Added Successfully' : 'Terminal App Updated Successfully',
        modalSize: modalSize || 800,
        fields: [
            {
                name: 'application_type',
                label: 'App Type',
                placeholder: "App Type",
                isRequired: true,
                requiredMessage: 'App Type is Required',
                type: 'select',
                colNo: 1,
                order: 1,
                extraProps:{
                    url: '/api/v1/enums/TerminalAppInstallerTypeChoices/combo/',
                    onChange: (value)=>setApplicationType(value)
                }
            },
            {
                name: 'app_name',
                label: 'App Name',
                placeholder: "'name' in package.json/productName in tauri.conf.json",
                isRequired: true,
                requiredMessage: 'App Name is Required',
                type: 'input',
                colNo: 1,
                order: 2,
                hidden: application_type === 2 || application_type === 3
            },
            {
                name: 'version',
                label: 'Version',
                placeholder: "Version in package.json or tauri.conf.json",
                isRequired: true,
                requiredMessage: 'Version is Required',
                type: 'input',
                colNo: 1,
                order: 3
            },
            // {
            //     name: 'product_name',
            //     label: 'Product Name',
            //     placeholder: "'productName' in build directory",
            //     isRequired: true,
            //     requiredMessage: 'Product Name is required',
            //     type: 'input',
            //     colNo: 1,
            //     order: 4
            // },
            {
                name: 'app_label',
                label: 'App Label',
                placeholder: "Label to View on Installer App",
                isRequired: true,
                requiredMessage: 'App Label is required',
                type: 'input',
                colNo: 1,
                order: 4,
                hidden: application_type === 2 || application_type === 3
            },
            {
                name: 'button_bg',
                label: 'Background Color',
                placeholder: "Button Color on Installer App (Hex code)",
                isRequired: true,
                requiredMessage: 'BG is required',
                type: 'input',
                colNo: 1,
                order: 5,
                hidden: application_type === 2 || application_type === 3
            },
            {
                name: 'file',
                label: 'Installer File',
                placeholder: 'Installer File',
                isRequired: true,
                requiredMessage: 'Installer FIle Required',
                type: 'file',
                colNo: 1,
                order: 6
            },
            {
                name: 'package',
                label: 'Package',
                placeholder: 'Installer File',
                isRequired: false,
                requiredMessage: '',
                type: 'file',
                colNo: 1,
                order: 7
            },
            {
                name: 'has_external_config',
                label: 'Require Config',
                placeholder: '',
                isRequired: false,
                requiredMessage: '',
                type: 'checkbox',
                colNo: 2,
                order: 1,
                extraProps: {
                    onChange: onchange = (event) => {
                        if (event.target.name === 'has_external_config') {
                            if (event.target.checked) {
                                setHiddenConfigFilename(false);
                                setHiddenConfig(false);
                            } else {
                                setHiddenConfigFilename(true);
                                setHiddenConfig(true);
                            }
                        }
                    }
                },
                hidden: application_type === 2 || application_type === 3
            },
            {
                name: 'config_file_name',
                label: 'Config File Name',
                placeholder: 'Enter Config.json file name',
                isRequired: false,
                requiredMessage: '',
                type: 'input',
                colNo: 2,
                order: 2,
                hidden:  hiddenConfigFileName || application_type === 2 || application_type === 3,
            },
            {
                name: 'config',
                label: 'Config',
                placeholder: 'Config (JSON object)',
                isRequired: false,
                requiredMessage: '',
                type: 'textarea',
                extraProps: {
                    rows: 2
                },
                colNo: 2,
                order: 3,
                hidden:  hiddenConfig || application_type === 2 || application_type === 3,
            },
            {
                name: 'release_summary',
                label: 'Release Summary',
                placeholder: 'Release Short Summary',
                isRequired: false,
                requiredMessage: '',
                type: 'textarea',
                extraProps: {
                    rows: 2
                },
                colNo: 2,
                order: 4,
            },
            {
                name: 'require_machine_id',
                label: 'Require Machine ID',
                placeholder: 'Machine ID Requirement',
                isRequired: true,
                requiredMessage: 'Enter Please',
                type: 'select',
                extraProps: {
                    data: [{id: 1, name: 'Yes'}, {id: 0, name: 'No'}]
                },
                colNo: 2,
                order: 5,
                hidden: application_type === 2 || application_type === 3
            },
            {
                name: 'require_mgs_core',
                label: 'Require MGS Core',
                placeholder: 'MGS Core Requirement',
                isRequired: false,
                requiredMessage: 'Enter Please',
                type: 'select',
                extraProps: {
                    url: '/api/v1/enums/CoreRequirementTypeChoices/combo/',
                },
                colNo: 2,
                order: 6,
                hidden: application_type === 2 || application_type === 3
            }
        ]
    }
    const handleOnClose =()=>{
        setInitialValues({});
        defaultValues.application_type = 1;
        setHiddenConfigFilename(true);
        setHiddenConfig(true);
        setApplicationType(1);
        if(onClose){
            onClose()
        }
    }

    const handleSuccessSubmit=()=>{
        setInitialValues({});
        defaultValues.application_type = 1;
        setHiddenConfigFilename(true);
        setHiddenConfig(true);
        setApplicationType(1);
        if(onSuccessSubmit){
            onSuccessSubmit()
        }
    }
    const onFinish=(values, handleError, form)=>{
        const formData = new FormData();
        const requestConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        for (let name in values) {
            const value = values[name];
            if (value instanceof File ) {
                formData.append(name, value, value.name);
            }
            // Append other values to the FormData object
            else if(value !== 0 &&(!value || value === 'undefined' || value === 'null')) {
                formData.append(name, '');
            }
            else{
                formData.append(name, value);
            }
        }
        formData.append('application_type', application_type)
        if (!edit) {
            http.post(config.addUrl, formData, requestConfig).then((resp) => {
                if (resp.status === 201) {
                    message.success(config.addSuccessMessage || resp.data.message || 'Added Successfully');
                    if (onSuccessSubmit) {
                        handleSuccessSubmit()
                        form.resetFields();
                    } else if (onClose) {
                        handleOnClose()
                        form.resetFields();
                    }
                }
            }).catch((error) => {
                handleError(error);
                if (onErrorSubmit) {
                    onErrorSubmit()
                }
            })
        }
        else{
            formData.append('config', form.getFieldValue('config'));
            formData.append('config_file_name', form.getFieldValue('config_file_name'));
            http.patch(config.editUrl, formData).then((resp) => {
                if (resp.status === 200) {
                    message.success(config.addSuccessMessage || resp.data.message || 'Added Successfully');
                    if (onSuccessSubmit) {
                        handleSuccessSubmit()
                        form.resetFields();
                    } else if (onClose) {
                        handleOnClose()
                        form.resetFields();
                    }
                }
            }).catch((error) => {
                handleError(error);
                if (onErrorSubmit) {
                    onErrorSubmit()
                }
            })
        }
    }

    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/terminal_apps/${editItem?.id}/`;
            http.get(dataUrl).then((resp)=>{
                resp.data.file = resp.data.filename;
                resp.data.config =  JSON.stringify(resp.data.config);
                if(resp.data.has_external_config === 1){
                    setHiddenConfigFilename(false);
                    setHiddenConfig(false);
                }
                else{
                    setHiddenConfigFilename(true);
                    setHiddenConfig(true);
                }
                setApplicationType(resp.data.application_type);
                defaultValues.application_type = resp.data.application_type;
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    return <AddPageModal onFinish={onFinish} config={config} visible={visible} onClose={handleOnClose} onSuccessSubmit={handleSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues} defaultValues={defaultValues}/>

}

export default TerminalAppAddPage;