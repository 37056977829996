import DataTable from "../../components/DataTable/DataTable";
import {useState} from "react";
import http from "../../http";
import {Button, message, Space, Tooltip, Typography} from "antd";
import TerminalAppAddPage from "./TerminalAppAddPage";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";


const TerminalAppListPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [reload, setReload] = useState(false);
    const [edit, setEdit] = useState(false);
    const [item, setItem] = useState(false);
    const handleOpenModal = (item, edit) => {
        if(edit){
            setEdit(true);
            setItem(item);
            setShowModal(true);
        }
        else {
            setEdit(false);
            setItem(null);
            setShowModal(true);

        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEdit(false);
        setItem(null);
    };
    const handleSuccessAdd = () =>{
        setShowModal(false);
        setEdit(false);
        setItem(null);
        setReload(!reload);
    }

    const handleDeleteClick = (item)=>{
        http.delete(`/api/v1/terminal_apps/${item.id}/delete/`).then((resp)=>{
            setReload(!reload);
        }).catch((err)=>{
            if(err.response){
                message.error(err.response.data.message);
            }
        })
    }
    const renderEditIcon=(record)=>{
        if(record.is_latest === 1){
            return <Typography.Link onClick={()=>handleOpenModal(record, true)}><EditOutlined /></Typography.Link>
        }
        // return <Typography.Link style={{color:'grey'}} onClick={()=>message.error('Can\'t be edited this item')}><EditOutlined /></Typography.Link>
    }
    const columns = [
        {
            title: 'App Name',
            dataIndex: 'app_name',
            key: 'app_name',
            width: '10%'
        },
        // {
        //     title: 'Product Name',
        //     dataIndex: 'product_name',
        //     key: 'product_name',
        //     width: '10%',
        // },
        {
            title: 'App Label',
            dataIndex: 'app_label',
            key: 'app_label',
            width: '10%',
        },
        {
            title: 'Version',
            dataIndex: 'version',
            key: 'version',
            width: '10%',
        },
        {
            title: 'Installer Name',
            dataIndex: 'filename',
            key: 'filename',
            width: '10%',
        },
        // {
        //     title: 'Download URL',
        //     dataIndex: 'download_url',
        //     key: 'download_url',
        //     width: '10%',
        // },
        {
            title: 'External Config',
            dataIndex: 'has_external_config',
            key: 'has_external_config',
            render: (text) => (
                     <div>{text === 1? 'True': 'False'}</div>
             ),
            width: '10%',
        },
        {
            title: 'Config Filename',
            dataIndex: 'config_file_name',
            key: 'config_file_name',
            width: '10%',
        },
        // {
        //     title: 'Config',
        //     dataIndex: 'config',
        //     key: 'config',
        //     render: (text) => (
        //              <div style={{ wordWrap: 'break-word', wordBreak: 'break-all', textOverflow:"-" }}>{text? JSON.stringify(text): ''}</div>
        //      ),
        //     width: '12%',
        // },
        {
            title: 'Machine ID Req.',
            dataIndex: 'require_machine_id',
            key: 'machine_id',
            render: (text) => (
                     <div>{text === 1? 'True': 'False'}</div>
             ),
            width: '10%',
        },
        {
            title: 'Core Req.',
            dataIndex: 'core_requirement_type_text',
            key: 'require_mgs_core',
            width: '10%',
        },
        {
            title: 'Latest',
            dataIndex: 'is_latest',
            key: 'machine_id',
            render: (text) => (
                     <div>{text === 1? 'True': 'False'}</div>
             ),
            width: '10%',
        },
        {
            title: 'Action',
            key: 'action',
            fixed:'right',
            width: '12%',
            align: 'center',
            render: (record) => (
                <Space size="middle">
                    {renderEditIcon(record)}
                </Space>
            )
        },
    ];

    const searchFields = [
        {
            name: 'app_name',
            type: 'input',
            label: 'App Name',
            placeholder: 'App Name'
        }
    ];

    const data = [
        // Provide your table data if not using URL
    ];

    return (
        <>
            <DataTable
                reload={reload}
                pageTitle={'Terminal Applications'}
                columns={columns}
                url="/api/v1/terminal_apps/"
                searchFields={searchFields}
                pagination={true}
                pageButtons={[
                    <Button
                        type="primary"
                        shape={'circle'}
                        icon={<PlusOutlined/>}
                        style={{marginLeft: 16, background:'#377EF3'}}
                        onClick={()=>handleOpenModal()}
                    />
                ]}
            />
            <TerminalAppAddPage
                visible={showModal}
                onClose={handleCloseModal}
                onSuccessSubmit={handleSuccessAdd}
                edit={edit}
                editItem={item}
                reload={reload}
            />
        </>
    );
};

export default TerminalAppListPage;