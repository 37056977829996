import {Button, Col, Row, message} from "antd";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import http from "../../http";
import Timer from "../../components/Timer/Timer";


const OTPPage = () => {

    const [otp, setOTP] = useState(null);
    const { t } = useTranslation();
    const handleGenerateOTP=()=>{
        let url = `/api/v1/auth/otp/generate_otp/`;
        http.post(url)
            .then(response => {
                if(response.status === 200){
                    setOTP(response.data.otp)
                    setTimeout(()=>{
                        setOTP(null);
                    }, 120000)
                }
                else{
                    message.error("Error Generating OTP");
                }
            })
            .catch(error => {
                message.error("Error Generating OTP");
            });
    }

    return (
        <Row style={{display: 'flex', flexDirection:'column', textAlign: 'center', height: '10vh'}}>
            <Col style={{justifyContent: 'center', textAlign: 'center', margin:'auto', display:'flex', flexDirection:'column'}}>
                {
                    otp ?
                        <div style={{justifyContent: 'center', textAlign: 'center', margin:'auto', display:'flex', flexDirection:'column'}}>
                            <div>{otp}</div>
                            <div>
                                <Timer maxTime={120} />
                            </div>
                        </div>
                        : <Button onClick={handleGenerateOTP}>Generate OTP</Button>}
            </Col>
        </Row>
    );
};

export default OTPPage;