import React from 'react';
import {useData} from "./DataContext";

const PermissionChecker = ({ requiredPermissions, children, ...props }) => {
  const {sharedData} = useData();
  const permissionList = sharedData.permitted_urls || window.sessionStorage.getItem('permissions') || [];
  const is_superuser = parseInt(sharedData.is_superuser||"0") || parseInt(window.sessionStorage.getItem('isSuperuser')||"0") || null;
  if (is_superuser || !requiredPermissions || requiredPermissions.length === 0) {
    return React.Children.map(children, child =>
      React.cloneElement(child, {
        ...props, // Spread all props to children
      })
    );
  } else if (requiredPermissions.length > 0) {
    const hasRequiredPermissions = requiredPermissions.every((permission) =>
      permissionList.includes(permission)
    );

    return hasRequiredPermissions ? (
      React.Children.map(children, child =>
        React.cloneElement(child, {
          ...props, // Spread all props to children
        })
      )
    ) : null;
  }
};

export default PermissionChecker;