import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";
import {useTranslation} from "react-i18next";

const ConstructorMachineAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem, machine, reload})=>{
    const [initialValues, setInitialValues] = useState({machine: machine.id});
    const defaultValues = {machine: machine.id};
    const { t } = useTranslation();
    const config = {
        addUrl: '/api/v1/machines/constructor_machines/add/',
        editUrl: edit ? `/api/v1/machines/constructor_machines/${editItem?.id}/edit/` : '',
        dataUrl: edit ? `/api/v1/machines/constructor_machines/${editItem?.id}/` : '',
        pageTitle: !edit ? t('lease_addpage_title') : t('addpage_edit_button_text')+ ' '+`${editItem.contract_code}`,
        colNo: 2,
        cancelButtonText: t('addpage_cancel_button_text'),
        saveButtonText: !edit ? t('addpage_save_button_text') : t('addpage_edit_button_text'),
        addSuccessMessage: !edit ? t('leaserecord_add_successful_message') : t('leaserecord_edit_successful_message'),
        modalSize: 700,
        fields: [
            {
                name: 'reference_code',
                label: t('leaseadd_reference_field'),
                placeholder: t('leaseadd_reference_field_placeholder'),
                isRequired: false,
                requiredMessage: "",
                colNo: 1,
                order: 1,
            },
            {
                name: 'machine',
                label: t('leaseadd_machine_field'),
                placeholder: t('leaseadd_machine_field_placeholder'),
                isRequired: true,
                requiredMessage: t('leaseadd_machine_field_required_text'),
                type: 'select',
                extraProps: {
                    url: `/api/v1/machines/combo/`,
                    value: machine.id,
                    disabled: true
                },
                colNo: 1,
                order: 2
            },
            {
                name: 'default_bucket',
                label: t('leaseadd_default_attachment_field'),
                placeholder: t('leaseadd_default_attachment_field_placeholder'),
                isRequired: machine.machine_type === 1 ? true: false,
                requiredMessage: t('default_attachment_required_validation_text'),
                type: 'select',
                extraProps: {
                    url: `/api/v1/machines/buckets/combo/?machine=${machine.id}`,
                },
                colNo: 1,
                order: 3,
                hidden: machine.machine_type === 2
            },
            {
                name: 'spare_buckets',
                label: t('leaseadd_spare_attachment_field'),
                placeholder: t('leaseadd_spare_attachment_field_placeholder'),
                isRequired: false,
                requiredMessage: "",
                type: 'select',
                extraProps: {
                    url: `/api/v1/machines/buckets/combo/?machine=${machine.id}`,
                    mode: 'multiple'
                },
                colNo: 1,
                order: 4,
                hidden: machine.machine_type === 2
            },
            {
                name: 'constructor',
                label: t('leaseadd_constructor_field'),
                placeholder: t('leaseadd_constructor_field'),
                isRequired: true,
                requiredMessage: t('leaseadd_constructor_field_required_text'),
                type: 'select',
                extraProps: {
                    url: `/api/v1/constructors/available_constructors/combo/`
                },
                colNo: 2,
                order: 1
            },
            {
                name: 'start_date',
                label: t('leaseadd_start_field'),
                placeholder: t('leaseadd_start_field'),
                isRequired: true,
                requiredMessage: t('leaseadd_start_date_field_required_text'),
                type: 'date',
                colNo: 2,
                order: 2
            },
            {
                name: 'end_date',
                label: t('leaseadd_end_field'),
                placeholder: t('leaseadd_end_field'),
                isRequired: true,
                requiredMessage: t('leaseadd_end_date_field_required_text'),
                type: 'date',
                colNo: 2,
                order: 3
            },
            // {
            //     name: 'branch',
            //     label: 'Branch',
            //     placeholder: 'Branch',
            //     isRequired: true,
            //     requiredMessage: 'Branch Required',
            //     type: 'select',
            //     extraProps: {
            //         url: `/api/v1/auth/branches/combo/`
            //     },
            //     colNo: 1,
            //     order: 3
            // }
        ]
    }

    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/machines/constructor_machines/${editItem?.id}/`;
            http.get(dataUrl).then((resp)=>{
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    return <AddPageModal config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues} defaultValues={defaultValues}/>

}

export default ConstructorMachineAddPage;
