import {Input, Upload} from "antd";
import React, {useEffect} from 'react';
import FileUploadIcon from "../CustomIcons/FileUploadIcon";

const MyUploadInput = ({ value, onChange }) => {
  const [fileName, setFileName] = React.useState(value);
  const handleFileChange = (info) => {
      setFileName(info.file.name);
      onChange(info);
  };
  useEffect(()=>{
    if(value) {
      if (value instanceof File ) {
        setFileName(value.name)
      }
      else{
        setFileName(value);
      }
    }
  }, [value])

  return (
    <Upload
      accept=".pdf,.doc,.docx,.txt,.png,.jpg,.jpeg,.dxf,.csv,.xml,.json,.exe,.zip"
      beforeUpload={() => false}
      onChange={handleFileChange}
      showUploadList={false}
    >
      <Input
        prefix={<FileUploadIcon />}
        // disabled={true}
        placeholder="Click to select file"
        readOnly
        value={fileName || ''}
      />
    </Upload>
  );
};

export default MyUploadInput;
