import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Row} from "antd";


const UserMachineListPage = (props) => {
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();


  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (data)=>{
    return null;
  }

    const buildCardBody = (data)=>{
        const cardBodyFields = {'number': t('machine_carnumber_field'), 'machine_type_text':  t('machine_type_field'), 'year_made': t('machine_yearmade_field'), 'manufacture': t('machine_manufacturer_field')};
        let innerContents = [];
        if(cardBodyFields) {
            for (const key in cardBodyFields) {
                const nestedKeys = key.split(".");
                let value = data;
                for (const nestedKey of nestedKeys) {
                    value = value[nestedKey];
                    if (value === undefined) {
                        break;
                    }
                }
                if( key === 'machine_type_text'){
                    innerContents.push(<Row key={key}>{cardBodyFields[key]}: {t(value)}</Row>)
                }
                else{
                    innerContents.push(<Row key={key}>{cardBodyFields[key]}: {value}</Row>)
                }
            }
        }
        return <Row style={{flexDirection:'column', justifyContent:'start'}} key={'cardContent'}>

            {innerContents}

        </Row>
    }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return [<label style={{color: !data.status ? 'red' : 'green'}}>{!data.status ? t('machinelist_license_status2') : t('machinelist_license_status1')}</label>, <label style={{color:'blue'}}>{t(`${data.lease_status_text}`)}</label>]
  }

  const config = {
    pageSize: props.pageSize || 10,
    url: `/api/v1/machines/?assigned=${props.user.id}`,
    pageTitle: t('user_machine_listpage_title'),
    pageSubtitle: t('user_machine_listpage_subtitle'),
    deleteTitle: t('machine_delete_confirmation_text'),
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {
      'number': t('machine_carnumber_field'),
      'machine_type_text': t('machine_type_field'),
      'year_made': t('machine_yearmade_field'),
      'manufacture': t('machine_manufacturer_field')
    },
    cardFooterFields: [],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
    hideAddButton: true,
    hideEdit: true,
    disableDelete: true,
    viewPermission: ['machine-detail'],
  }

  const handleDetailClick=(item)=>{
    navigate('/machine/detail/', { replace: false, state: {machine: item} })
  }

  return (
      <>
        <ListPage
            config={config}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />
      </>
  );
};

export default UserMachineListPage;
