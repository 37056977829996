import DataTable from "../../components/DataTable/DataTable";
import {useState} from "react";
import http from "../../http";
import {Button, message, Space, Table, Tooltip, Typography} from "antd";
import {EditOutlined, PlusOutlined, CloseOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";

const TerminalDeviceListPage = () => {
    const [reload, setReload] = useState(false);
    const { t } = useTranslation();

    const handleTerminalDeviceExpire=(record)=>{
        setReload(true);
        http.post(`/api/v1/auth/tenants/terminal_devices/${record.id}/expire_terminal_device/`).then((resp)=>{
                if (resp.status === 200) {
                    message.success(t(resp?.data?.message))
                    setReload(false);
                }
                else{
                    setReload(false);
                    message.error(t(resp?.data?.message))
                }
            }).catch((err)=>{
                setReload(false);
                message.error(t("terminal_device_de_registration_failed_text"))
            })
    }
    const handleMachineBindingExpire=(record)=>{
        setReload(true);
        http.post(`/api/v1/auth/tenants/terminal_devices/${record.id}/expire_machine_binding/`).then((resp)=>{
                if (resp.status === 200) {
                    message.success(t(resp?.data?.message))
                    setReload(false);
                }
                else{
                    setReload(false);
                    message.error(t(resp?.data?.message))
                }
            }).catch((err)=>{
                setReload(false);
                message.error(t("machine_binding_de_registration_failed_text"))
            })
    }

     const renderExpireIcon=(record)=>{
        if(record.status_text == 'Active'){
        return <CustomConfirmPopup
                    title={t("device_de_registration_confirm_text")}
                    onConfirm={()=>handleTerminalDeviceExpire(record)}
                    icon={<CloseOutlined style={{color: 'red'}}/>}
                    okText={t('yes_text')}
                    cancelText={t('no_text')}
                    okType='default'
                >
                    <PermissionChecker requiredPermissions={["expire-terminal-device"]}><Typography.Link ><CloseOutlined style={{color: 'red'}}/></Typography.Link></PermissionChecker>
                </CustomConfirmPopup>

        }
        return null
    }
    const renderMachineExpireIcon=(record)=>{
        if(record.status == 'Active'){
        return <CustomConfirmPopup
                    title={t("machine_binding_de_registration_confirm_text")}
                    onConfirm={()=>handleMachineBindingExpire(record)}
                    icon={<CloseOutlined style={{color: 'red'}}/>}
                    okText={t('yes_text')}
                    cancelText={t('no_text')}
                    okType='default'
                >
                    <PermissionChecker requiredPermissions={["expire-machine-binding"]}><Typography.Link ><CloseOutlined style={{color: 'red'}}/></Typography.Link></PermissionChecker>
                </CustomConfirmPopup>

        }
        return null
    }
    const machineColumns = [
        {
            title: 'Machine ID',
            dataIndex: 'machine_id',
            key: 'machine_id',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            render: (text) => (
                <Tooltip title={text} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all', color: text === 'Expired' ? 'red': 'green' }}>{text}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            fixed:'right',
            width: '10%',
            align: 'center',
            render: (record) => (
                <Space size="middle">
                    {renderMachineExpireIcon(record)}
                </Space>
            )
        },
    ]
    const columns = [
        {
            title: 'Device Name',
            dataIndex: 'device_name',
            key: 'device_name',
            width: '30%'
        },
        {
            title: 'Mac Address',
            dataIndex: 'device_mac',
            key: 'server_domain',
            width: '50%',
            ellipsis: true,
            render: (text) => (
                <Tooltip title={text} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status_text',
            key: 'status_text',
            width: '10%',
            ellipsis: true,
            render: (text) => (
                <Tooltip title={text} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all', color: text === 'Expired' ? 'red': 'green' }}>{text}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            fixed:'right',
            width: '10%',
            align: 'center',
            render: (record) => (
                <Space size="middle">
                    {renderExpireIcon(record)}
                </Space>
            )
        },
    ];

    const searchFields = [
        {
            name: 'device_name',
            type: 'input',
            label: 'Name',
            placeholder: 'Device Name'
        },
        {
            name: 'device_mac',
            type: 'input',
            label: 'Mac Address',
            placeholder: 'Device Mac'
        }
    ];

    return (
        <>
            <DataTable
                reload={reload}
                pageTitle={'Terminal Devices'}
                columns={columns}
                url="/api/v1/auth/tenants/terminal_devices/"
                searchFields={searchFields}
                pagination={true}
                pageButtons={[

                ]}
                extendedRowRender={(record) => (
                    <Table
                        locale={{ emptyText: t('table_no_data_text') }}
                        key={record.id}
                        dataSource={record.machines}
                        loading={reload}
                        sticky={true}
                        columns={machineColumns}
                        pagination={false}
                    />
                )}
                rowExpandable={(record) => record.machines.length > 0}
            />
        </>
    );
};

export default TerminalDeviceListPage;