import React, { useEffect, useState } from 'react';
import {DatePicker, Input} from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import {CalendarOutlined} from "@ant-design/icons";
import CalendarIcon from "../CustomIcons/CalendarIcon";


const dateRender = (current) => {
  const style = {};
  if (current.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) {
    style.border = '1px solid grey';
    style.borderRadius = '50%';
    style.background = 'grey'
  }
  return (
    <div className="ant-picker-cell-inner" style={style}>
      {current.date()}
    </div>
  );
};


const DateField = ({ initialValue, dateFormat = 'YYYY-MM-DD', offset = 0, onChange, ...restProps }) => {
  const [value, setValue] = useState(null);
  const prefixIcon = <CalendarOutlined />;

  useEffect(() => {
    let defaultValue = dayjs();

    if (offset !== 0) {
      defaultValue = defaultValue.add(offset, 'day');
    }

    if (initialValue) {
      defaultValue = dayjs(initialValue);
    }

    handleDateChange(defaultValue);
  }, [initialValue, offset]);

  const handleDateChange = (date) => {
    setValue(date);
    if (onChange) {
      onChange(date ? date.format(dateFormat) : '');
    }
  };

  const getPopupContainer = (trigger) => {
    return trigger.parentNode;
  };

  return (
    <DatePicker
      {...restProps}
      defaultValue={dayjs()} // Set defaultValue to current date
      value={value}
      format={dateFormat}
      onChange={handleDateChange}
      locale="en" // set locale here
      dateRender={dateRender}
      style={{width: '100%'}}
      getPopupContainer={getPopupContainer}
      suffixIcon={<CalendarIcon />}
    />
  );
};

export default DateField;
