import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

const Timer = (props) => {
  const [timer, setTimer] = useState(props.maxTime);
  const initialTime = 0;

  useEffect(() => {
    // Function to update the timer every second
    const updateTimer = () => {
      setTimer((prevTimer) => {
        if (prevTimer > initialTime) {
          return prevTimer - 1;
        } else {
          clearInterval(intervalId);
          return initialTime;
        }
      });
    };

    // Interval to update the timer every second
    const intervalId = setInterval(updateTimer, 1000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures that the effect runs only once (on mount)

  return (
    <div>
      <h1>Timer: {timer} seconds</h1>
    </div>
  );
};

Timer.propTypes = {
    maxTime: PropTypes.number
};

export default Timer;
