import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";
import {useTranslation} from "react-i18next";


const VRSServerAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem, modalSize, reload})=>{
    const [initialValues, setInitialValues] = useState({});
    const { t } = useTranslation();
    const config = {
        addUrl: '/api/v1/machines/vrs/add/',
        editUrl: edit ? `/api/v1/machines/vrs/${editItem.id}/edit/` : '',
        dataUrl: edit? `/api/v1/machines/vrs/${editItem.id}/` : '',
        pageTitle: !edit ? t('vrs_server_addpage_title') : t('addpage_edit_button_text') + ' ' +`${editItem.name || ''}`,
        colNo: 2,
        cancelButtonText:t('addpage_cancel_button_text'),
        saveButtonText: !edit ? t('addpage_save_button_text') : t('addpage_edit_button_text'),
        addSuccessMessage: !edit ? t('vrs_server_add_successful_message') : t('vrs_server_edit_successful_message'),
        modalSize: 600,
        fields: [
            {
                name: 'name',
                label: t('vrs_server_server_field'),
                placeholder: t('vrs_server_server_field_placeholder'),
                isRequired: true,
                requiredMessage: t('vrs_server_server_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 1
            },
            {
                name: 'server_id',
                label: t('vrs_server_serverid_field'),
                placeholder: t('vrs_server_serverid_field_placeholder'),
                isRequired: true,
                requiredMessage: t('vrs_server_serverid_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 2
            },
            {
                name: 'provider',
                label: t('vrs_server_provider_field'),
                placeholder: t('vrs_server_provider_field_placeholder'),
                isRequired: true,
                requiredMessage: t('vrs_server_provider_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 3
            },
            {
                name: 'url',
                label: t('vrs_server_url_field'),
                placeholder: t('vrs_server_url_field_placeholder'),
                isRequired: true,
                requiredMessage: t('vrs_server_url_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 4
            },
            {
                name: 'port',
                label: t('vrs_server_port_field'),
                placeholder: t('vrs_server_port_field_placeholder'),
                isRequired: true,
                requiredMessage: t('vrs_server_port_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 5
            },
            {
                name: 'password',
                label: t('vrs_server_password_field'),
                placeholder: t('vrs_server_password_field_placeholder'),
                isRequired: true,
                requiredMessage: t('vrs_server_password_field_required_text'),
                type: 'input',
                colNo: 2,
                order: 1,
            },
            {
                name: 'mountpoint',
                label: t('vrs_server_mountpoint_field'),
                placeholder: t('vrs_server_mountpoint_field_placeholder'),
                isRequired: true,
                requiredMessage: t('vrs_server_mountpoint_field_required_text'),
                type: 'input',
                colNo: 2,
                order: 2,
            }
        ]
    }

    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/machines/vrs/${editItem?.id}/`;
            http.get(dataUrl).then((resp)=>{
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    return <AddPageModal config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues}/>

}

export default VRSServerAddPage;