import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import http from "../../http";
import VRSServerAddPage from "./VRSServerAddPage";
import {message} from "antd";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";


const VRSServerListPage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (data)=>{
    return null;
  }

  const buildCardBody = (data)=>{
    return null;
  }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return null;
  }

  const config = {
    url: '/api/v1/machines/vrs/',
    pageTitle: t('vrs_server_listpage_title'),
    pageSubtitle: t('vrs_server_listpage_subtitle'),
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    deleteTitle: t('vrs_delete_confirmation_text'),
    cardBodyFields: {
      'server_id': t('vrs_server_serverid_field'),
      'url': t('vrs_server_url_field'),
      'port': t('vrs_server_port_field'),
      'provider': t('vrs_server_provider_field'),
      'mountpoint': t('vrs_server_mountpoint_field')
    },
    cardFooterFields: [],
    addPermission: ['vrs-server-create'],
    editPermission: ['vrs-server-edit'],
    deletePermission: ['vrs-server-delete'],
    viewPermission: ['vrs-server-detail'],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
  }
  const handleOpenModal = (item, edit) => {
    if(edit){
      setEdit(true);
      setItem(item);
      setShowModal(true);
    }
    else {
      setEdit(false);
      setItem(null);
      setShowModal(true);

    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEdit(false);
    setItem(null);
  };
  const handleSuccessAdd = () =>{
    setShowModal(false);
    setEdit(false);
    setItem(null);
    setReload(!reload);
  }

  const handleDeleteClick = (item)=>{
    http.delete(`/api/v1/machines/vrs/${item.id}/delete/`).then((resp)=>{
      setReload(!reload);
    }).catch((err)=>{
      message.error(t(err?.response?.data?.message))
    })
  }
  const handleDetailClick=(item)=>{
    navigate('/vrs_server/detail/', { state: {vrs: item} })
  }

  return (
      <>
        <ListPage
            config={config}
            onAddClick={handleOpenModal}
            onEditClick={(item)=>handleOpenModal(item, true)}
            onDeleteClick={(item)=>handleDeleteClick(item)}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />
        <VRSServerAddPage
            visible={showModal}
            onClose={handleCloseModal}
            onSuccessSubmit={handleSuccessAdd}
            edit={edit}
            editItem={item}
            reload={reload}
        />
      </>
  );
};

export default VRSServerListPage;
