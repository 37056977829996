import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store";
import routes from "./routes"; // Import your list of routes
import BaseLayout from "./components/Layout/BaseLayout";
import PrivateRoute from "./PrivateRoute";
import ContentNotFound from "./ContentNotFound";
import {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import React from 'react'
import {DataProvider, useData} from "./DataContext";
import http from "./http";
import NotificationProvider from "./NotificationProvider";


const App = (props) => {
    const dispatch = useDispatch();
    const { setData } = useData();
    const storeState = store.getState();
    const [isPermissionsLoaded, setIsPermissionsLoaded] = useState(storeState?.auth?.isLoggedIn ? false: true);

    const styles = {
        loadingContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            fontSize: '1.5em'
        }
    };

    useEffect(() => {
        let permissions = window.sessionStorage.getItem('permissions');
        if(permissions || !storeState?.auth?.isLoggedIn){
            setIsPermissionsLoaded(true);
        }
        else if(storeState?.auth?.isLoggedIn && !permissions) {
            http.get('/o/get_user_info/').then((res) => {
                if (res.status === 200) {
                    setData(res.data);
                    window.sessionStorage.setItem('permissions', res.data.permitted_urls);
                    window.sessionStorage.setItem('isSuperuser', res.data.is_superuser);
                    window.sessionStorage.setItem('isAdminUser', res.data.is_admin);
                    setIsPermissionsLoaded(true);
                }
                else{
                    setIsPermissionsLoaded(true);
                }
            }).catch(()=>{
                setIsPermissionsLoaded(true);
            })
        }
        // window.addEventListener('storage', handleStorageChange);
        // return () => {
        //     window.removeEventListener('storage', handleStorageChange);
        // };
    }, [storeState?.auth?.isLoggedIn]);

    if (!isPermissionsLoaded) {
        return (
            <div style={styles.loadingContainer}>
                <div>Loading...</div>
            </div>
        );
    }
    return (
        <Router>
            <Routes>
                {
                    routes.map((route) => {
                        return <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                            element={
                                <PrivateRoute {...route}>
                                    {(route.path === '/login' || route.path === '/content_not_found' || route.path === '/permission_denied') ? <route.component {...props}/>:<BaseLayout><route.component {...props}/></BaseLayout>}
                                </PrivateRoute>
                            }
                        />
                    })
                }
                <Route path='*' element={<ContentNotFound />} />
            </Routes>
        </Router>
    );
};

const Root = () => {
    return (
        <Provider store={store}>
            <DataProvider>
                <NotificationProvider>
                    <App />
                </NotificationProvider>
            </DataProvider>
        </Provider>
    );
};

export default Root;
