import {Divider, Row, Col, Space, Table, Typography, Modal} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import MachineLeaseStatusListPage from "./MachineLeaseStatusListPage";
import {EyeOutlined} from '@ant-design/icons';
import http from "../../http";
import ConstructorMachineView from "../ConstructorMachines/ConstructorMachineView"
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import DataTable from "../../components/DataTable/DataTable";

const ConstructorDetailPage=(props)=>{
    const navigate = useNavigate();
    const location = useLocation();
    const {state} = location;
    const [loading, setLoading] = useState(false);
    // const [assignmentDataSource, setAssignmentDataSource] = useState([]);
    const [constructor, setConstructor] = useState(state?.constructor);
    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [constructorMachine, setConstructorMachine] = useState(null);
    const [isValidState, setValidState] = useState(!!state?.constructor);
    const { t } = useTranslation();
    const historyColumns = [
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'startDate',
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'endDate',
        },
        {
            title: 'Status',
            dataIndex: 'status_text',
            key: 'statusText',
        },
    ]
    const columns = [
        {
            title: t('lease_history_col1'),
            dataIndex: 'contract_code',
            key: 'contract_code',
        },
        {
            title: t('lease_history_col10'),
            dataIndex: 'reference_code',
            key: 'reference_code',
        },
        {
            title: t('lease_history_col2'),
            dataIndex: 'start_date',
            key: 'startDate',
        },
        {
            title: t('lease_history_col3'),
            dataIndex: 'end_date',
            key: 'endDate',
        },
        {
            title: t('lease_history_col4'),
            dataIndex: 'machine_name',
            key: 'machineName',
        },
        {
            title: t('lease_history_col5'),
            dataIndex: 'machine_data',
            key: 'machine_type',
            render: (text)=>(
                <div>{t(text.machine_type_text)}</div>
            )
        },
        {
            title: t('lease_history_col6'),
            dataIndex: 'machine_data',
            key: 'machineId',
            render: (text)=>(
                <div>{text.identifier}</div>
            )
        },
        {
            title: t('lease_history_col7'),
            dataIndex: 'status_text',
            render: (text)=>(
                <div>{t(text)}</div>
            ),
            key: 'statusText',
        },
        // {
        //     title: t('lease_history_col8'),
        //     dataIndex: 'constructor_data',
        //     key: 'leaseTo',
        //     render: (text) => (
        //             <div>{(text.company_name)}</div>
        //     ),
        // },
        {
            title: t('lease_history_col9'),
            key: 'action',
            fixed:'right',
            render: (record) => (
                <Space size="middle">
                    <PermissionChecker requiredPermissions={['constructor-machine-detail']}>
                        <Typography.Link onClick={()=>{setConstructorMachine(record); setViewModalVisible(true)}}><EyeOutlined/></Typography.Link>
                    </PermissionChecker>
                </Space>
            )
        },
    ]
    const fetchConstructorData=()=>{
        http.get(`/api/v1/constructors/available_constructors/${constructor?.id}/`).then((resp)=>{
            setLoading(false);
            setConstructor(resp.data);
        }).catch((err)=>{
            setLoading(false);
        })
    }

    // const fetchDefaultData =()=>{
    //     setLoading(true);
    //     http.get(`/api/v1/machines/constructor_machines/?constructor=${constructor.id}`).then((resp)=>{
    //         setAssignmentDataSource(resp.data.results);
    //         setLoading(false);
    //     }).catch(()=>{
    //         setLoading(false);
    //     })
    // }

    useEffect(()=>{
        if(!state){
            navigate('/content_not_found',{replace: true})
        }
        if(isValidState) {
            fetchConstructorData()
        }
        // fetchDefaultData()
    }, [state])

    const buildBreadcrumbs=()=>{
        let breadcrumbs = [];
        if(state.breadcrumbs){
            state.breadcrumbs.map((item, index)=>{
                breadcrumbs.push(
                    <><Link to={item.to} state={item.state} key={index}>{item.title}</Link> > </>
                )
            })
            breadcrumbs.push(<>{constructor.full_name}</>)
            return breadcrumbs;
        }
        return <><Link to={'/constructors'}>{t('constructor_list_title')}  </Link> >  {constructor.full_name}</>;
    }

    const handleViewModalCancel=()=>{
        setViewModalVisible(false)
    }
    if(isValidState) {
        return (
            <>
                <Modal
                    open={viewModalVisible}
                    title={t('contract_detail_view_title', {contract_code: constructorMachine?.contract_code})}
                    onCancel={handleViewModalCancel}
                    onOk={handleViewModalCancel}
                    maskClosable={false}
                    width={800}
                    cancelText={t('modalCancelText')}
                    okText={t('modal_okText')}
                >
                    <ConstructorMachineView constructorMachine={constructorMachine}/>
                </Modal>
                <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <Row style={{height: '5%', color: "black", fontWeight: 'bold', justifyContent: 'space-between'}}>
                        <Col>
                            <Space>{buildBreadcrumbs()}</Space>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col style={{lineHeight: '1.5rem'}}><h1>{constructor.full_name}</h1></Col>
                        <Col>{t('constructor_company_name_field')} : {constructor.company_name}</Col>
                        <Col>{t('constructor_addpage_email')} : {constructor.email}</Col>
                        <Col>{t('constructor_addpage_contactno')} : {constructor.contact_no}</Col>
                        <Col>{t('constructor_addpage_address1')} : {constructor.address_1}</Col>
                        {/*<Col>{t('constructor_addpage_email')} : {constructor.tax_no}</Col>*/}
                        {/*<Col>{t('constructor_addpage_bin')} : {constructor.bin}</Col>*/}
                    </Row>
                    <Divider/>
                    <Row style={{overflow: "hidden", height: '60%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <MachineLeaseStatusListPage pageSize={3} constructor={constructor}/>
                        </div>
                    </Row>
                    <Divider style={{marginTop: '10px'}}/>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col>
                            <p style={{fontWeight: "bold", fontSize: "800"}}> {t('lease_historylist_title')} </p>
                        </Col>
                        <Col>
                            <DataTable
                                pagination={true}
                                locale={{emptyText: t('table_no_data_text')}}
                                sticky={true}
                                loading={loading}
                                url={`/api/v1/machines/constructor_machines/?constructor=${constructor.id}`}
                                columns={columns}
                                extendedRowRender={(record) => (
                                    <DataTable
                                        locale={{emptyText: t('table_no_data_text')}}
                                        key={record.id}
                                        data={record.histories}
                                        loading={loading}
                                        sticky={true}
                                        columns={historyColumns}
                                        pagination={false}
                                    />
                                )}
                                rowExpandable={(record) => record.histories.length > 0}
                            />
                        </Col>
                    </Row>
                </div>
            </>

        )
    }
    else{
        return null;
    }
}

export default ConstructorDetailPage;
