import i18n from "i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";


const TOKEN = 'wlu_gyBCb58gjYkLwXeiQQYQaodJewtBclhRN35b'

const fetchWeblateLastModifiedTimestamp = async (lang) => {

    const headers = {
        "Authorization": `Token ${TOKEN}`,
    };
    try {

        const response = await fetch(`https://translation.waktech.com/api/translations/resonest/mgs-partner-cloud/${lang}/`, {
            headers,
            cache: 'no-store'
        });
        const data = await response.json();
        return {'last_change': data.last_change, 'total': data.total.toString()};
    }
    catch (err){
        const localLastModified = localStorage.getItem(`i18next_lang_${lang}_last_modified`) || ''
        const localTotal = localStorage.getItem(`i18next_lang_${lang}_total`) || '0'
        return {'last_change': localLastModified, 'total': localTotal};
    }

};



const isTranslationUpdated = async (lang) => {

    const localLastModified = localStorage.getItem(`i18next_lang_${lang}_last_modified`) || ''

    const localTotal = localStorage.getItem(`i18next_lang_${lang}_total`) || '0'

    const {last_change: cloudLastModified, total: cloudTotal} = await fetchWeblateLastModifiedTimestamp(lang)

    // RE-SET LOCAL LAST MODIFIED && LOCAL TOTAL

    localStorage.setItem(`i18next_lang_${lang}_last_modified`, cloudLastModified)

    localStorage.setItem(`i18next_lang_${lang}_total`, cloudTotal)

    return !(localLastModified == cloudLastModified) || !(localTotal == cloudTotal)

}

const fetchUpdatedTranslation = async (lang) => {
    const headers = {
        "Authorization": `Token ${TOKEN}`,
    };
    const response = await fetch(`https://translation.waktech.com/api/translations/resonest/mgs-partner-cloud/${lang}/file/`, {headers, cache: 'no-store'});
    const data = await response.json();
    return data;
}


i18n
.use(ChainedBackend)
.use(initReactI18next)
.init(
    {
        backend: {
            backends: [
                HttpBackend,
            ],
            backendOptions: [
                {
                    loadPath: '{{lng}}', // Will be passed as URL to custom request
                    allowMultiLoading: false,
                    request: function (options, url, payload, callback) {
                        isTranslationUpdated(url).then((isUpdated) => {
                            if (isUpdated) {
                                fetchUpdatedTranslation(url).then((translation) => {
                                    callback(null, { data: translation, status: 200 });
                                }).catch((err)=>{
                                    callback(null, { data: {}, status: 200 });
                                })
                            }else {
                                // Fetch From Local
                                const translation = localStorage.getItem(`i18next_lang_${url}`)
                                try {
                                    callback(null, { data: JSON.parse(translation), status: 200 });
                                }catch(err){
                                    callback(true, {status:404})
                                }
                            }
                        })
                      
                    }
                },
                {
                    prefix: 'i18next_lang_',
                }
            ]
        },
        fallbackLng: "en",
        keySeperator: false,
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        returnObjects: true,
    }
);

// DEFAULT LANGUAGE
let currentLang = localStorage.getItem('i18next_lang')
if (currentLang === null) { 
    currentLang = "en"
    localStorage.setItem('i18next_lang', currentLang)
}

i18n.changeLanguage(currentLang).then(() => {
    // Save To Cache
    const _cacheLang = i18n.getDataByLanguage(currentLang)
    localStorage.setItem(`i18next_lang_${currentLang}`, JSON.stringify(_cacheLang["translation"]))
})


export default i18n;