import AddPageWithoutModal from "../../components/AddPage/AddPageWithoutModal";
import {useEffect, useState} from "react";
import http from "../../http";
import {Tabs, message} from "antd";
import {Modal, Form, Button} from 'antd';
import {useTranslation} from "react-i18next";


const ConstructorAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem})=>{
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({})
    const [formErrors, setFormErrors] = useState({});
    const [form] = Form.useForm();
    const [withCode, setWithCode] =  useState(1)
    const { i18n, t } = useTranslation();
    const config ={
        addUrl: '/api/v1/constructors/available_constructors/add/',
        editUrl: edit ? `/api/v1/constructors/available_constructors/${editItem.id}/edit/` : '',
        dataUrl: edit? `/api/v1/constructors/available_constructors/${editItem.id}/` : '',
        pageTitle: !edit ? t('constructor_addpage_title') : t('addpage_edit_button_text') + ' ' + `${editItem.company_name}`,
        colNo: 2,
        cancelButtonText: t('addpage_cancel_button_text'),
        saveButtonText: !edit ? t('addpage_save_button_text') : t('addpage_edit_button_text'),
        addSuccessMessage: !edit ? t('constructor_add_successful_message') : t('constructor_edit_successful_message'),
        fields: [
            {
                name: 'identifier',
                label: t('constructor_identifier_field'),
                placeholder: t('constructor_identifier_field_placeholder'),
                isRequired: true,
                requiredMessage: t('constructor_identifier_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 1,
                hidden: withCode === 2 || edit
            },
            {
                name: 'company_name',
                label: t('constructor_company_name_field'),
                placeholder: t('constructor_company_name_field_placeholder'),
                isRequired: true,
                requiredMessage: t('constructor_company_name_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 2,
                hidden: withCode === 1
            },
            {
                name: 'email',
                label: t('constructor_addpage_email'),
                placeholder: t('constructor_addpage_email'),
                isRequired: true,
                requiredMessage: t('constructor_email_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 3,
                hidden: withCode === 1
            },
            {
                name: 'contact_no',
                label: t('constructor_addpage_contactno'),
                placeholder: t('constructor_addpage_contactno'),
                isRequired: true,
                requiredMessage: t('constructor_contactno_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 4,
                hidden: withCode === 1
            },
            {
                name: 'address_1',
                label: t('constructor_addpage_address1'),
                placeholder: t('constructor_addpage_address1'),
                isRequired: false,
                requiredMessage: '',
                colNo: 1,
                order: 5,
                hidden: withCode === 1
            },
            {
                name: 'address_2',
                label: t('constructor_addpage_address2'),
                placeholder: t('constructor_addpage_address2'),
                isRequired: false,
                requiredMessage: '',
                colNo: 1,
                order: 6,
                hidden: withCode === 1
            },
            {
                name: 'first_name',
                label: t('constructor_addpage_firstname'),
                placeholder: t('constructor_contact_person_field'),
                isRequired: true,
                requiredMessage: t('constructor_contact_person_required_text'),
                colNo: 2,
                order: 1,
                hidden: withCode === 1
            }
//             {
//                 name: 'last_name',
//                 label: t('constructor_addpage_lastname'),
//                 placeholder: t('constructor_contact_person_field'),
//                 isRequired: false,
//                 requiredMessage: '',
//                 colNo: 2,
//                 order: 2,
//                 hidden: withCode === 1
//             }

        ]
    }

    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/constructors/available_constructors/${editItem.id}/`;
            http.get(dataUrl).then((resp)=>{
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    const onChange = (key) => {
        setWithCode(key);
    };

    const handleCancel = () => {
        form.resetFields();
        setFormErrors({});
        // if(!edit){
        //     onChange(1)
        // }
        // else{
        //     onChange(2)
        // }
        onClose();
    };

    useEffect(()=>{
        if(edit){
            onChange(2)
        }
        else{
            onChange(1)
        }
    }, [edit])


    const buildContent=()=>{
        return <Tabs activeKey={withCode} items={items} onChange={onChange} />
    }
    const buildEditContent=()=>{
        return <Tabs activeKey={2} items={editItems} onChange={onChange} />
    }

    const handleSubmit = (values, handleError) => {
        setLoading(true);
        values.with_code = withCode === 1 ? 1: 0;
        try {
            if (!edit) {
                http.post(config?.addUrl, values).then((resp) => {
                    if (resp.status === 201) {
                        message.success(config.addSuccessMessage || resp.data.message || 'Added Successfully');
                        if (onSuccessSubmit) {
                            onSuccessSubmit()
                            handleCancel();
//                                form.setFieldsValue(defaultValues);
                        } else if (onClose) {
                            handleError(resp)
//                                onClose();
                            form.resetFields();
//                                form.setFieldsValue(defaultValues);
                        }
                    }
                }).catch((error) => {
                    handleError(error);
                    if (onErrorSubmit) {
                        onErrorSubmit()
                    }
                })
            }
            else{
                http.patch(config.editUrl, values).then((resp) => {
                    if (resp.status === 200) {
                        message.success(config.addSuccessMessage || resp.data.message || 'Added Successfully');
                        if (onSuccessSubmit) {
                            onSuccessSubmit();
                            form.resetFields();
//                                form.setFieldsValue(defaultValues);
                        } else if (onClose) {
                            onClose();
                            form.resetFields();
//                                form.setFieldsValue(defaultValues);
                        }
                    }
                }).catch((error) => {
                    handleError(error);
                    if (onErrorSubmit) {
                        onErrorSubmit()
                    }
                })
            }

//             onClose();
        } catch (error) {
        } finally {
           setTimeout(()=>{
                setLoading(false);
            }, 1000)
        }
    };

    const items = [
        {
            key: 1,
            label: t('with_mgs_code_label'),
            children: <AddPageWithoutModal onFinish={handleSubmit} errors={formErrors} form={form} config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues}/>,
        },
        {
            key: 2,
            label: t('without_mgs_code_label'),
            children: <AddPageWithoutModal onFinish={handleSubmit} errors={formErrors} form={form} config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues}/>,
        }
    ]
    const editItems = [
        {
            key: 2,
            label: t('without_mgs_code_label'),
            children: <AddPageWithoutModal onFinish={handleSubmit} errors={formErrors} form={form} config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues}/>,
        }
    ]

    return (
        <Modal
            open={visible}
            title={config.pageTitle}
            onCancel={handleCancel}
            maskClosable={false}
            footer={i18n.language === 'en' ? [
                <Button key="cancel" onClick={handleCancel} loading={loading}>
                    {config.cancelButtonText || t('addpage_cancel_button_text')}
                </Button>,
                <Button key="submit" type="primary" onClick={() => form.submit()} style={{background:'#377EF3'}} loading={loading}>
                    {config.saveButtonText || t('addpage_save_button_text')}
                </Button>,
            ]: [
                <Button key="submit" type="primary" onClick={() => form.submit()} style={{background:'#377EF3'}} loading={loading}>
                    {config.saveButtonText || 'Save'}
                </Button>,
                <Button key="cancel" onClick={handleCancel} loading={loading}>
                    {config.cancelButtonText || 'Cancel'}
                </Button>
            ]}
            width={600}
        >
            {edit? buildEditContent(): buildContent()}
        </Modal>
    )

}

export default ConstructorAddPage;
