import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";
import {useTranslation} from "react-i18next";


const UserAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem})=>{
    const [initialValues, setInitialValues] = useState({})
    const [hiddenRole, setHiddenRole] = useState(false)
    const { t } = useTranslation();
    const config ={
        addUrl: '/api/v1/auth/users/add/',
        editUrl: `/api/v1/auth/users/${editItem?.id}/edit/`,
        dataUrl: `/api/v1/auth/users/${editItem?.id}/`,
        pageTitle: !edit ? t('staff_addpage_title') : t('addpage_edit_button_text') + ' ' +`${editItem?.first_name || editItem?.username}`,
        colNo: 2,
        cancelButtonText: t('addpage_cancel_button_text'),
        saveButtonText: !edit ? t('addpage_save_button_text') : t('addpage_edit_button_text'),
        addSuccessMessage: !edit ? t('staff_successful_add_message') : t('staff_successful_edit_message'),
        fields: [
            {
                name: 'first_name',
                label: t('user_firstname_field'),
                placeholder: t('user_firstname_field_placeholder'),
                isRequired: true,
                requiredMessage: t('user_firstname_required_message'),
                type: 'input',
                colNo: 1,
                order: 1
            },
            // {
            //     name: 'last_name',
            //     label: t('staff_lastname_field'),
            //     placeholder: t('staff_lastname_field_placeholder'),
            //     isRequired: false,
            //     requiredMessage: '',
            //     type: 'input',
            //     colNo: 1,
            //     order: 2
            // },
            {
                name: 'email',
                label: t('staff_email_field'),
                placeholder: t('staff_email_field_placeholder'),
                isRequired: true,
                requiredMessage: t('staff_email_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 3
            },
            {
                name: 'mobile',
                label: t('staff_mobile_field'),
                placeholder: t('staff_mobile_field_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'input',
                colNo: 1,
                order: 4,
            },
            {
                name: 'slack_id',
                label: t('staff_slackid_field'),
                placeholder: t('staff_slackid_field_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'input',
                colNo: 1,
                order: 5
            },
            {
                name: 'username',
                label: t('staff_username_field'),
                placeholder: t('staff_username_field_placeholder'),
                isRequired: true,
                requiredMessage: t('staff_username_field_required_text'),
                colNo: 2,
                order: 1
            },
            {
                name: 'password',
                label: t('staff_password_field'),
                placeholder: t('staff_password_field_placeholder'),
                isRequired: true,
                requiredMessage: t('staff_password_required_text'),
                type: 'password',
                colNo: 2,
                order: 2,
                hidden: !!edit
            },
            {
                name: 'branches',
                label: t('staff_branches_field'),
                placeholder: t('staff_branch_field_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'select',
                extraProps: {
                    url: '/api/v1/auth/branches/combo/',
                    mode: 'multiple'
                },
                colNo: 2,
                order: 3,
            },
            // {
            //     name: 'data_access',
            //     label: t('staff_dataaccess_field'),
            //     placeholder: t('select_placeholder'),
            //     isRequired: false,
            //     requiredMessage: '',
            //     type: 'select',
            //     extraProps: {
            //         url: '/api/v1/enums/DataAccessChoices/combo/',
            //         withTranslation: true
            //     },
            //     colNo: 2,
            //     order: 4
            // },
            {
                name: 'role',
                label: t('staff_role_field'),
                placeholder: t('select_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'select',
                extraProps: {
                    url: '/api/v1/auth/group_roles/combo/'
                },
                hidden:  hiddenRole,
                colNo: 2,
                order: 5
            },
            {
                name: 'is_adminuser',
                label: t('staff_admin_field'),
                placeholder: '',
                isRequired: false,
                requiredMessage: '',
                type: 'checkbox',
                colNo: 2,
                order: 6,
                extraProps: {
                    onChange: onchange = (event) => {
                        event.target.checked ? setHiddenRole(true) : setHiddenRole(false)
                    }
                }
            },
        ]
    }

    useEffect(()=>{
        if(editItem){
            let dataUrl = `/api/v1/auth/users/${editItem?.id}/`;
            http.get(dataUrl).then((resp)=>{
                setInitialValues(resp.data);
                resp.data.is_adminuser ? setHiddenRole(true) : setHiddenRole(false)
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    return <AddPageModal config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues}/>

}

export default UserAddPage;
