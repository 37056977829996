import {Divider, Row, Col, Table, Space, Button, Modal, Typography, message} from 'antd';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {
    DeleteOutlined,
    EditOutlined,
    RiseOutlined,
    UploadOutlined,
    EyeOutlined,
} from '@ant-design/icons';
import http from '../../http';
import ConstructorMachineAddPage from './ConstructorMachineAddPage';
import MachineRegistryFileAddPage from "./MachineRegistryFileAddPage";
import ConstructorMachineView from "../ConstructorMachines/ConstructorMachineView"
import {getReadableTimeFromTimeStamp} from '../../utils/common'
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";
import DataTable from "../../components/DataTable/DataTable";

const MachineDetailPage=(props)=>{
    const location = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [qrModalVisible, setQRModalVisible] = useState(false);
    const [qrCodeUrl, setQRCodeUrl] = useState('');
    const [constructorMachine, setConstructorMachine] = useState({});
    const [isMachineRegistryModalVisible, setIsMachineRegistryModalVisible] = useState(false);
    const {state} = location;
    const [loading, setLoading] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [edit, setEditConstructorMachine] = useState(false)
    const [assignmentTableReload, setAssignmentTableReload] = useState(true);
    const [bucketDataReload, setBucketDataReload] = useState(true);
    const [registryFileReload, setRegistryFileReload] = useState(true);
    const [machine, setMachine] = useState(state?.machine);
    const [isValidState, setValidState] = useState(!!state?.machine);
    const { t } = useTranslation();

    const navigate = useNavigate();

    // const fetchDefaultData =()=>{
    //     setLoading(true);
    //     http.get(`/api/v1/machines/constructor_machines/?machine=${machine.id}`).then((resp)=>{
    //         setAssignmentDataSource(resp.data.results);
    //         setLoading(false);
    //     }).catch(()=>{
    //         setLoading(false);
    //     })
    // }

    // const fetchBucketData =()=>{
    //     setLoading(true);
    //     http.get(`/api/v1/machines/buckets/?machine=${machine.id}`).then((resp)=>{
    //         setBucketData(resp.data.results)
    //         setLoading(false);
    //     }).catch(()=>{
    //         setLoading(false);
    //     })
    // }

    // const fetchMachineRegistry =()=>{
    //     setLoading(true);
    //     http.get(`/api/v1/machines/machine_registry_files/?machine=${machine.id}`).then((resp)=>{
    //         setAssignmentDataSourceMachineRegistry(resp.data.results)
    //         setLoading(false);
    //     }).catch(()=>{
    //         setLoading(false);
    //     })
    // }

    const fetchMachineData=()=>{
        http.get(`/api/v1/machines/${machine?.id}/`).then((resp)=>{
            setLoading(false);
            setMachine(resp.data);
        }).catch((err)=>{
            setLoading(false);
        })
    }

    const fetchQRCode = ()=>{
        http.get(`/api/v1/machines/${machine?.id}/qr_code/`, ).then((resp)=>{
            const binaryDataString=atob(resp.data.qr);
            const byteNumbers = new Array(binaryDataString.length);

            for (let i = 0; i < binaryDataString.length; i++) {
                byteNumbers[i] = binaryDataString.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: 'image/png'});
            const url = URL.createObjectURL(blob);
            setQRCodeUrl(url);
        }).catch((err)=>{
            setLoading(false);
        })
    }

    useEffect(()=>{
        if(!state){
            navigate('/content_not_found',{replace: true})
        }
        if(isValidState) {
            fetchMachineData()
            // fetchDefaultData()
            // fetchMachineRegistry()
            // fetchBucketData()
            fetchQRCode()
        }
    }, [isValidState])

    const handleConstructorMachineEdit = (record)=>{
        setEditConstructorMachine(true);
        setEditItem(record);
        setIsModalVisible(true);
    }

    const renderEditIcon=(record)=>{
        if(!record.status && record.lease_status !== 2){
            return <Typography.Link onClick={()=>handleConstructorMachineEdit(record)}><EditOutlined /></Typography.Link>
        }
        return <Typography.Link style={{color:'grey'}} onClick={()=>message.error(t('generic_edit_error_text'))}><EditOutlined /></Typography.Link>
    }

    const renderSubmitIcon=(record)=>{
        if(record.lease_status !== 2) {
            return <CustomConfirmPopup
                title={record.status === 0 ? t('leaserecord_approve_confirmation_text') : t('leaserecord_update_confirmation_text')}
                onConfirm={() => record.status === 0 ? handleSubmitConstructorMachine(record) : updateConstructorMachine(record)}
                icon={<RiseOutlined/>}
                okText={t('yes_text')}
                cancelText={t('no_text')}
                disabled={false}
                okType='default'
            >
                <Typography.Link><RiseOutlined/></Typography.Link>
            </CustomConfirmPopup>
        }
        return <Typography.Link style={{color:'grey'}}><RiseOutlined/></Typography.Link>
    }

    const handleSubmitConstructorMachine = (record) => {
        if (!record.machine_data.status) {
            message.error(t('machine_not_registered_validation_text'))
        } else {
            http.post(`/api/v1/machines/constructor_machines/${record.id}/1/record_machine_lease/`).then((resp)=>{
                if (resp.status === 200) {
                    message.success(t('leaserecord_submit_successful_text'))
                    setLoading(false);
                    setAssignmentTableReload(!assignmentTableReload)
                }
            }).catch((err)=>{
                setLoading(false);
                message.error(t(err.response.data.error))
            })
        }
    }

    const updateConstructorMachine = (record) => {
        if (!record.machine_data.status) {
            message.error(t('machine_not_registered_validation_text'))
        } else {
            http.post(`/api/v1/machines/constructor_machines/${record.id}/update_machine_lease/`).then((resp)=>{
                if (resp.status === 200) {
                    message.success(t('leaserecord_update_successful_text'))
                    setLoading(false);
                    setAssignmentTableReload(!assignmentTableReload)
                }
            }).catch((err)=>{
                setLoading(false);
                message.error(t(err.response.data.error))
            })
        }
    }

    const handleBucketDelete=(record)=>{
        let url =`/api/v1/machines/buckets/${record.id}/delete/`;
        http.delete(url).then((resp)=>{
            if(resp.status === 200){
                message.success(t('attachment_deletion_succesful_text', {attachment_name: record.name}))
                setBucketDataReload(!bucketDataReload);
            }
            else if(resp.status === 400){
                message.error(t(resp.data.message))
            }
        }).catch((err)=>{
            let err_message = t("attachment_deletion_failed_text", {attachment_name: record.name});
            if(err?.response?.data?.message){
                err_message = t(err?.response?.data?.message, {attachment_name: record.name});
            }
            message.error(err_message)
        })
    }

    const fileColumns = [
        {
            title: t('profile_filelist_col1'),
            dataIndex: 'created',
            key: 'created',
            render: (value) => {
                let readable_timestamp = getReadableTimeFromTimeStamp(value);
                return <div>{readable_timestamp}</div>
            }
        },
        {
            title: t('profile_filelist_col2'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('profile_filelist_col3'),
            dataIndex: 'status_text',
            key: 'status',
            render: (text) => (
                <div style={{ color: text == 'active_label' ? 'green': 'red'}}>{t(text)}</div>
            ),
        }
    ]

    const bucket_columns = [
        {
            title: t('profile_filelist_col1'),
            dataIndex: 'created',
            key: 'created',
            render: (value) => {
                let readable_timestamp = getReadableTimeFromTimeStamp(value);
                return <div>{readable_timestamp}</div>
            }
        },
        {
            title: t('bucket_col1'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('bucket_col6'),
            dataIndex: 'status_text',
            render: (text) => (
                <div style={{ color: text == 'active_label' ? 'green': 'red'}}>{t(text)}</div>
            ),
            key: 'statusText',
        },
        {
            title: t('bucket_col7'),
            key: 'action',
            fixed:'right',
            width: '10%',
            render: (record) => (
                <Space size="middle">
                    <PermissionChecker requiredPermissions={['activate-bucket-profile']}>
                        {machine?.lease_status === 0 ?
                            <CustomConfirmPopup
                                title={t('attachment_delete_confirmation_text', {attachment_name: record.name})}
                                onConfirm={() => handleBucketDelete(record)}
                                icon={<RiseOutlined/>}
                                okText={t('yes_text')}
                                cancelText={t('no_text')}
                                disabled={false}
                                okType='default'
                            ><Typography.Link ><DeleteOutlined/></Typography.Link>
                            </CustomConfirmPopup>: null}
                    </PermissionChecker>
                </Space>
            )
        },
    ]

    const historyColumns = [
        {
            title: t('lease_historylist_col2'),
            dataIndex: 'start_date',
            key: 'startDate',
        },
        {
            title: t('lease_historylist_col3'),
            dataIndex: 'end_date',
            key: 'endDate',
        },
        {
            title: t('lease_historylist_col6'),
            dataIndex: 'status_text',
            render: (text)=>(
                <div>{t(text)}</div>
            ),
            key: 'statusText',
        },
    ]

    const columns = [
        {
            title: t('lease_historylist_col1'),
            dataIndex: 'contract_code',
            key: 'contract_code',
        },
        {
            title: t('lease_historylist_col9'),
            dataIndex: 'reference_code',
            key: 'reference_code',
        },
        {
            title: t('lease_historylist_col2'),
            dataIndex: 'active_history',
            key: 'startDate',
            render: (text)=>(
                <div>{text.start_date}</div>
            )
        },
        {
            title: t('lease_historylist_col3'),
            dataIndex: 'active_history',
            key: 'endDate',
            render: (text)=>(
                <div>{text.end_date}</div>
            )
        },

        {
            title: t('lease_historylist_col4'),
            dataIndex: 'machine_name',
            key: 'machineName',
        },
        {
            title: t('lease_historylist_col5'),
            dataIndex: 'machine_data',
            key: 'machineId',
            render: (text)=>(
                <div>{text.number}</div>
            )
        },
        {
            title: t('lease_historylist_col6'),
            dataIndex: 'status_text',
            render: (text)=>(
                <div>{t(text)}</div>
            ),
            key: 'statusText',
        },
        {
            title: t('lease_historylist_col7'),
            dataIndex: 'constructor_data',
            key: 'leaseTo',
            render: (text) => (
                <div>{(text.company_name)}</div>
            ),
        },
        {
            title: t('lease_historylist_col8'),
            key: 'action',
            fixed:'right',
            render: (record) => (
                <Space size="middle">
                    <PermissionChecker requiredPermissions={['constructor-machine-edit']}>
                        {renderEditIcon(record)}
                    </PermissionChecker>
                    {record.status === 0 && record.lease_status !== 2 ?<CustomConfirmPopup
                        title={t('leaserecord_delete_confirmation_text')}
                        onConfirm={()=>handleAssignmentDelete(record)}
                        icon={<DeleteOutlined/>}
                        okText={t('yes_text')}
                        cancelText={t('no_text')}
                        disabled={false}
                        okType='default'
                    >
                        <PermissionChecker requiredPermissions={['constructor-machine-delete']}>
                            <Typography.Link><DeleteOutlined/></Typography.Link>
                        </PermissionChecker>
                    </CustomConfirmPopup> : null}
                    <PermissionChecker requiredPermissions={['record-machine-lease', 'update-machine-lease']}>
                        {renderSubmitIcon(record)}
                    </PermissionChecker>
                    <PermissionChecker requiredPermissions={['constructor-machine-detail']}>
                        <Typography.Link onClick={()=>{setConstructorMachine(prevData=>{
                                return {...prevData, ...record}
                            }
                        );
                            setViewModalVisible(true);
                        }}><EyeOutlined/></Typography.Link>
                    </PermissionChecker>
                </Space>
            )
        },
    ]

    const handleAssignmentDelete=(record)=>{
        http.delete(`/api/v1/machines/constructor_machines/${record.id}/delete/`).then((resp)=>{
            if(resp.status === 204){
                message.success(t('leaserecord_delete_successful_message'));
                setAssignmentTableReload(!assignmentTableReload)
            }
            else{
                message.error(t('leaserecord_delete_failed_message'));
            }
        })
    }

    const buildBreadcrumbs=()=>{
        let breadcrumbs = [];
        if(state?.breadcrumbs){
            state?.breadcrumbs.map((item, index)=>{
                breadcrumbs.push(
                    <><Link to={item.to} state={item.state} key={index}>{item.title}</Link> > </>
                )
            })
            breadcrumbs.push(<>{machine?.name}</>)
            return breadcrumbs;
        }
        return <><Link to={'/machines'}>{t('machinelist_title')}  </Link> >  {machine?.name}</>;
    }

    const handleSuccessAdd = () =>{
        setEditConstructorMachine(false);
        setEditItem(null);
        setIsModalVisible(false);
        setAssignmentTableReload(!assignmentTableReload)
    }
    const handleSuccessAddMachineRegistry = () =>{
        setIsMachineRegistryModalVisible(false);
        setRegistryFileReload(!registryFileReload);
        setBucketDataReload(!bucketDataReload);
    }

    const renderConstructorMachineForm = () => {
        return (
            <ConstructorMachineAddPage
                visible={isModalVisible}
                onClose={()=>{setEditItem(null); setEditConstructorMachine(false); setIsModalVisible(false)}}
                machine={machine}
                edit={edit}
                editItem={editItem}
                onSuccessSubmit={handleSuccessAdd}
            />
        );
    };
    const renderMachineRegistryFileForm = () => {
        return (
            <MachineRegistryFileAddPage
                visible={isMachineRegistryModalVisible}
                onClose={()=>{setEditItem(null); setEditConstructorMachine(false); setIsMachineRegistryModalVisible(false)}}
                machine={machine}
                onSuccessSubmit={handleSuccessAddMachineRegistry}
            />
        );
    };

    const handleViewModalCancel=()=>{
        setAssignmentTableReload(!assignmentTableReload);
        setViewModalVisible(false)
    }
    const handleQRModalCancel=()=>{
        setQRModalVisible(false)
    }
    const handleQRCodeClick=()=>{
        setQRModalVisible(true)
    }
    const handlePrint = () => {
        // Open a new window
        const printWindow = window.open('', '_blank');

        // Write the content to the new window
        printWindow.document.write('<html><head><title>Print</title></head><body>');
        printWindow.document.write('<style>.print-only { display: block; }</style>');
        printWindow.document.write(document.querySelector('.print-only').innerHTML);
        printWindow.document.write('</body></html>');

        // Close the document and trigger printing
        printWindow.print();
        printWindow.close();

    };
    const leaseHistorySearchFields = [
        {
            name: 'status',
            type: 'select',
            label: 'Rental Status',
            placeholder: 'Select Rental Status',
            extraProps: {
                withTranslation: true,
                url: '/api/v1/enums/ConstructorMachineStatusChoices/combo/'
            }
        }]

    if(isValidState) {
        return (
            <>
                {renderConstructorMachineForm()}
                {renderMachineRegistryFileForm()}
                <Modal
                    open={viewModalVisible}
                    okText={t('modal_okText')}
                    cancelText={t('modalCancelText')}
                    title={t('contract_detail_view_title', {contract_code: constructorMachine?.contract_code})}
                    onCancel={handleViewModalCancel}
                    onOk={handleViewModalCancel}
                    maskClosable={false}
                    width={800}
                >
                    <ConstructorMachineView constructorMachine={constructorMachine}/>
                </Modal>
                <Modal
                    open={qrModalVisible}
                    title={`QR Code for ${machine?.name}`}
                    onCancel={handleQRModalCancel}
                    onOk={handleQRModalCancel}
                    maskClosable={false}
                    footer={[
                        <Button key="download">
                            <a
                                key="download"
                                href={qrCodeUrl}
                                download={`machine_qr_code_${machine?.id}.png`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Download
                            </a>
                        </Button>,
                        <Button key="submit" type="primary" onClick={() => handlePrint()} style={{background: '#377EF3'}}>
                            Print
                        </Button>,

                    ]}
                >
                    <div className={'print-only'}>
                        <img src={qrCodeUrl} alt="QR Code" style={{width: '100%'}}/>
                    </div>
                </Modal>
                <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <Row style={{height: '5%', color: 'black', fontWeight: 'bold', justifyContent: 'space-between'}}>
                        <Col>
                            <Space>{buildBreadcrumbs()}</Space>
                        </Col>
                        <Col>
                            <Space>
                                <PermissionChecker requiredPermissions={['constructor-machine-create']}>
                                    <Button type={'primary'}
                                            onClick={() => setIsModalVisible(true)}>{t('machine_lease_button_text')}</Button>
                                </PermissionChecker>
                            </Space>

                    </Col>
                </Row>
                <Row style={{display: 'flex', flexDirection: 'column'}}>
                    <Col style={{lineHeight: '1.5rem'}}><h2>{machine?.name}</h2></Col>
                    <Col>
                        <Row style={{display: 'flex', flexDirection: 'row'}}>
                            <Col>
                                <Col style={{lineHeight: '1.5rem'}}><h3>{t('machine_detail_data1')}</h3></Col>
                                <Col>{t('machine_carnumber_field')} : {machine?.number}</Col>
                                <Col>{t('machine_yearmade_field')} : {machine?.year_made}</Col>
                                <Col>{t('machine_manufacturer_field')} : {machine?.manufacture}</Col>
                                <Col>{t('machine_model_field')} : {machine?.model}</Col>
                                <Col>{t('machine_registeredby_field')} : {machine?.created_by?.full_name}</Col>
                                <Col> {t('machine_lastmodified_field')}: {machine?.updated_by?.full_name}</Col>
                            </Col>
                            <Divider type='vertical'/>
                            <Col>
                                <Col style={{lineHeight: '1.5rem'}}><h1>{t('machine_detail_data2')}</h1></Col>
                                <Col>{t('machine_type_field')} : {t(machine?.machine_type_text)}</Col>
                                <Col>{t('machine_identifier_field')} : {machine?.identifier}</Col>
                                <Col>{t('machine_systemversion_field')} : {''}</Col>
                                <Col>{t('machine_gnss_field')} : {''}</Col>
                                <Col>{t('machine_licensestatus_field')} : {t(machine?.license_status_text)}</Col>
                                <Col>{t('machine_assignto_field')} : {machine?.assigned_to_text}</Col>
                            </Col>
                            {/*<Divider type='vertical' />*/}
                            {machine?.number ?
                                <Col>
                                    <Col style={{lineHeight: '1.5rem'}}><h1></h1></Col>
                                    <Col>
                                        <div onClick={handleQRCodeClick} style={{cursor: 'pointer'}}>
                                            <img src={qrCodeUrl} alt="QR Code" width={40} height={40}/>
                                        </div>
                                    </Col>
                                </Col> : null}
                        </Row>
                    </Col>
                </Row>
                <Divider style={{marginTop: '10px'}}/>
                <Row style={{display: 'flex', flexDirection: 'column'}}>
                    <Col>
                        <Row style={{justifyContent: 'space-between'}}>
                            <Col span={12}>
                                <p style={{fontWeight: 'bold', fontSize: '800'}}>{t('registry_file_list_title')} </p>
                            </Col>
                            <Col span={12}>
                                <p align='right' style={{fontWeight: 'bold', fontSize: '800'}}>
                                    <PermissionChecker requiredPermissions={['machine-registry-file-create']}>
                                        <Button onClick={() => setIsMachineRegistryModalVisible(true)}
                                                icon={<UploadOutlined/>}></Button>
                                    </PermissionChecker>
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <DataTable
                            locale={{emptyText: t('table_no_data_text')}}
                            sticky={true}
                            reload={registryFileReload}
                            pagination={true}
                            url={`/api/v1/machines/machine_registry_files/?machine=${machine?.id}`}
                            columns={fileColumns}/>
                    </Col>
                </Row>
                <Divider style={{marginTop: '10px'}}/>
                {machine?.machine_type === 1 ? <Row style={{display: 'flex', flexDirection: 'column'}}>
                    <Col>
                        <p style={{fontWeight: 'bold', fontSize: '800'}}> {t('machine_bucketlist_title')} </p>
                    </Col>
                    <Col>
                        <DataTable
                            locale={{emptyText: t('table_no_data_text')}}
                            sticky={true}
                            reload={bucketDataReload}
                            pagination={true}
                            url={`/api/v1/machines/buckets/?machine=${machine?.id}`}
                            columns={bucket_columns}
                        />
                    </Col>
                </Row> : null}
                {machine?.machine_type === 1 ? <Divider style={{marginTop: '10px'}}/> : null}
                <Row style={{display: 'flex', flexDirection: 'column'}}>
                    <Col>
                        <p style={{fontWeight: 'bold', fontSize: '800'}}> {t('lease_historylist_title')} </p>
                    </Col>
                    <Col>
                        <DataTable
                            locale={{emptyText: t('table_no_data_text')}}
                            sticky={true}
                            reload={assignmentTableReload}
                            url={`/api/v1/machines/constructor_machines/?machine=${machine?.id}`}
                            columns={columns}
                            pagination={true}
                            searchFields={leaseHistorySearchFields}
                            extendedRowRender={(record) => (
                                <DataTable
                                    locale={{emptyText: t('table_no_data_text')}}
                                    key={record.id}
                                    data={record.histories}
                                    loading={loading}
                                    sticky={true}
                                    columns={historyColumns}
                                    pagination={true}
                                />
                            )}
                            rowExpandable={(record) => record.histories.length > 0}
                        />
                    </Col>
                </Row>
            </div>
        </>

        )
    }
    else{
        return null;
    }
}

export default MachineDetailPage;
