import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Row} from "antd";

const getToday = () => {
  const today = new Date();
  const year = today.getFullYear().toString();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');
  return year + "-" + month + "-" + day;
};

const MachineLeaseStatusListPage = (props) => {
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (data)=>{
    return null;
  }

  const buildCardBody = (data)=>{
    const cardBodyFields = {
      'machine_data.machine_type_text': t('machine_type_field'),
      'machine_data.number': t('machine_carnumber_field'),
      'machine_data.year_made': t('machine_yearmade_field'),
      'machine_data.manufacture': t('machine_manufacturer_field')
    }
    let innerContents = [];
    if(cardBodyFields) {
      for (const key in cardBodyFields) {
        const nestedKeys = key.split(".");
        let value = data;
        for (const nestedKey of nestedKeys) {
          value = value[nestedKey];
          if (value === undefined) {
            break;
          }
        }
        if( key === 'machine_data.machine_type_text'){
          innerContents.push(<Row key={key}>{cardBodyFields[key]}: {t(value)}</Row>)
        }
        else{
          innerContents.push(<Row key={key}>{cardBodyFields[key]}: {value}</Row>)
        }
      }
    }
    return <Row style={{flexDirection:'column', justifyContent:'start'}} key={'cardContent'}>

      {innerContents}

    </Row>
  }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return [<label style={{color: !data.status ? 'red' : 'green'}}>{t(data.status_text)}</label>]
  }

  const config = {
    pageSize: props.pageSize || 10,
    url: `/api/v1/machines/constructor_machines/?constructor=${props.constructor.id}&status=1`,
    pageTitle: t('leasestatus_list_title'),
    pageSubtitle: t('leasestatus_list_subtitle'),
    deleteTitle: t('machine_delete_confirmation_text'),
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'machine_name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {
      'machine_data.machine_type_text': 'Machine Type',
      'machine_data.number': 'Car Number',
      'machine_data.year_made': 'Year Made',
      'machine_data.manufacture': 'Manufacturer'
    },
    cardFooterFields: [],
    addPermission: ['constructor-machine-create'],
    editPermission: ['constructor-machine-edit'],
    deletePermission: ['constructor-machine-delete'],
    viewPermission: ['machine-detail'],
    orderingFields: [
            {id: '-machine__name', name: t('machine_orderby_field1')},
            {id: 'machine__name', name: t('machine_orderby_field2')},
            {id: '-machine__number', name: t('machine_orderby_field3')},
            {id: 'machine__number', name: t('machine_orderby_field4')},
            {id: '-created', name: t('machine_orderby_field5')},
            {id: 'created', name: t('machine_orderby_field6')},
        ],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
    hideAddButton: true,
    hideEdit: true,
    disableDelete: true
  }

  const handleDetailClick=(item)=>{
    navigate('/machine/detail/', { replace: false, state: {machine: item.machine_data} })
  }

  return (
      <>
        <ListPage
            config={config}
            reload={reload}
            onDetailClick={handleDetailClick}
        />
      </>
  );
};

export default MachineLeaseStatusListPage;
