import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import MachineAddPage from "./MachineAddPage";
import http from "../../http";
import {useNavigate} from "react-router-dom";
import {message, Typography} from 'antd';
import {useTranslation} from "react-i18next";
import {Row} from 'antd';
import {CheckOutlined} from "@ant-design/icons";
import PermissionChecker from "../../PermissionChecker";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";




const MachineListPage = (props) => {

    const { t } = useTranslation();

    const buildPageHeader = (data) =>{
        return null;
    }
    const buildCardHeader = (data)=>{
        return null;
    }

    const buildCardBody = (data)=>{
        const cardBodyFields = {'number': t('machine_carnumber_field'), 'machine_type_text':  t('machine_type_field'), 'year_made': t('machine_yearmade_field'), 'manufacture': t('machine_manufacturer_field')};
        let innerContents = [];
        if(cardBodyFields) {
            for (const key in cardBodyFields) {
                const nestedKeys = key.split(".");
                let value = data;
                for (const nestedKey of nestedKeys) {
                    value = value[nestedKey];
                    if (value === undefined) {
                        break;
                    }
                }
                if( key === 'machine_type_text'){
                    innerContents.push(<Row key={key}>{cardBodyFields[key]}: {t(value)}</Row>)
                }
                else{
                    innerContents.push(<Row key={key}>{cardBodyFields[key]}: {value}</Row>)
                }
            }
        }
        return <Row style={{flexDirection:'column', justifyContent:'start'}} key={'cardContent'}>

            {innerContents}

        </Row>
    }

    const buildCardFooter=(data)=>{
        return null;
    }
    const handleLicenseSubmission=(data)=>{
        if(data.license_application){
            http.post(`/api/v1/machine_license_applications/${data.license_application.id}/submit/`).then((resp)=>{
                if(resp.status === 200){
                    message.success(t('machine_license_application_submit_successful_message'));
                    setReload(!reload);
                }
                else{
                    message.error(t(resp?.data?.error))
                }
            }).catch((error)=>{
                message.error(t(error.response?.data?.error))
            })

        }
    }

        const renderFooterFields=(data)=>{
        if(data.status === 0){
            return [
                data.license_status === 0 ?<CustomConfirmPopup
                    title={t('license_application_submit_confirmation_text')}
                    onConfirm={()=>handleLicenseSubmission(data)}
                    icon={<CheckOutlined/>}
                    okText={t('yes_text')}
                    cancelText={t('no_text')}
                    disabled={false}
                    okType='default'
                >
                    <PermissionChecker requiredPermissions={['machine-license-application-submit']}>
                        <Typography.Link><label style={{color:'red'}}>{
                           t('license_submit_button_text')
                        }</label></Typography.Link>
                    </PermissionChecker>
                </CustomConfirmPopup>:<label style={{color:[3, 4].includes(data.license_status) ? 'red': 'maroon'}}>{t(`${data.license_status_text}`)}</label>,
                <label style={{color:'blue'}}>{t(`${data.lease_status_text}`)}</label>
            ]
        }
        return [<label style={{color: 'green'}}>{t('machinelist_license_status1')}</label>, <label style={{color:'blue'}}>{t(`${data.lease_status_text}`)}</label>]
    }

    const buildFooterFields=(data)=>{
        return renderFooterFields(data);
    }

    const config = {
        url: '/api/v1/machines/',
        pageTitle: t('machinelist_title'),
        pageSubtitle: t('machinelist_subtitle'),
        deleteTitle: t('machine_delete_confirmation_text'),
        searchPlaceHolder: t('listpage_search_text') +'...',
        cardTitleField: 'name',
        cardDetailButtonText: t('listpage_detail_button_text'),
        cardDeleteButtonText: 'Delete Selected',
        cardBodyFields: {'number': t('machine_carnumber_field'), 'machine_type_text': t('machine_type_field'), 'year_made': t('machine_yearmade_field'), 'manufacture': t('machine_manufacturer_field')},
        cardFooterFields: [],
        addPermission: ['machine-create'],
        editPermission: ['machine-edit'],
        deletePermission: ['machine-delete'],
        viewPermission: ['machine-detail'],
        orderingFields: [
            {id: '-name', name: t('machine_orderby_field1')},
            {id: 'name', name: t('machine_orderby_field2')},
            {id: '-number', name: t('machine_orderby_field3')},
            {id: 'number', name: t('machine_orderby_field4')},
            {id: '-created', name: t('machine_orderby_field5')},
            {id: 'created', name: t('machine_orderby_field6')},
        ],
        'buildPageHeader': buildPageHeader,
        'buildCardHeader': buildCardHeader,
        'buildCardBody': buildCardBody,
        'buildCardFooter': buildCardFooter,
        'buildFooterFields': buildFooterFields,
    }
    const [showModal, setShowModal] = useState(false);
    const [reload, setReload] = useState(false);
    const [edit, setEdit] = useState(false);
    const [item, setItem] = useState(false);
    const navigate = useNavigate();
    const handleOpenModal = (item, edit) => {
        if(edit){
                setEdit(true);
                setItem(item);
                setShowModal(true);
        }
        else {
            setEdit(false);
            setItem(null);
            setShowModal(true);

        }
    };

    const handleCloseModal = () => {
        setEdit(false);
        setItem(null);
        setShowModal(false);

    };
    const handleSuccessAdd = () =>{
        setEdit(false);
        setItem(null);
        setShowModal(false);
        setReload(!reload);
    }

    const handleDeleteClick = (item)=>{
        if(item.lease_status === 0){
            http.delete(`/api/v1/machines/${item.id}/delete/`).then((resp)=>{
                if(resp.status===204){
                    message.success(t('machine_delete_successful_message'));
                    setReload(!reload);
                }
                else{
                    let message_text =  resp.data.message || resp.data.error || resp.data.success;
                    message.error(t(message_text))
                }
            }).catch((err)=>{
                message.error(t('generic_server_error_text'))
            })
        }
        else{
            message.error(t('machine_delete_validation_text'))
        }
    }

    const handleDetailClick=(item)=>{
        navigate('/machine/detail/', { replace: true, state: {machine: item} })
    }

    return (
        <>
            <ListPage
                config={config}
                onAddClick={handleOpenModal}
                onEditClick={(item)=>handleOpenModal(item, true)}
                onDeleteClick={(item)=>handleDeleteClick(item)}
                onDetailClick={(item)=>handleDetailClick(item)}
                reload={reload}
            />
            <MachineAddPage
                visible={showModal}
                onClose={handleCloseModal}
                onSuccessSubmit={handleSuccessAdd}
                edit={edit}
                editItem={item}
            />
        </>
    );
};

export default MachineListPage;
