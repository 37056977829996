import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";


const ExtensionRequestEditPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem})=>{
    const [initialValues, setInitialValues] = useState({})
    const config ={
        addUrl: '/api/v1/machines/lease_extension_requests/',
        editUrl: edit ? `/api/v1/machines/lease_extension_requests/${editItem.id}/edit/` : '',
        dataUrl: edit? `/api/v1/machines/lease_extension_requests/${editItem.id}/` : '',
        pageTitle: !edit ? 'Add Lease Duration Change Request' : `Edit Duration Change Request`,
        colNo: 2,
        cancelButtonText: 'Cancel',
        saveButtonText: !edit ? 'Save' : 'Edit',
        addSuccessMessage: !edit ? 'Lease Duration Change Request Added Successfully' : 'Lease Duration Change Request Updated Successfully',
        fields: [
            {
                name: 'from_date',
                label: 'From Date',
                placeholder: 'Enter Date',
                isRequired: true,
                requiredMessage: 'Start Date is Required',
                type: 'date',
                colNo: 1,
                order: 1
            },
            {
                name: 'to_date',
                label: 'To Date',
                placeholder: 'Enter Date',
                isRequired: true,
                requiredMessage: 'End Date is  Required',
                type: 'date',
                colNo: 1,
                order: 2
            },
        ]
    }

    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            http.get(config.dataUrl).then((resp)=>{
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    return <AddPageModal config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues}/>

}

export default ExtensionRequestEditPage;
